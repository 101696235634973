import { FC, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { Layout } from "antd"

import { ItemType } from "antd/es/menu/interface"
import { useLayoutQuery } from "../../../graphql"
import { Logo } from "../../logo"
import Navigation from "../../menu/Navigation"
import { SidebarLink } from "./SidebarLink"
import { useGeneralContext } from "../../../context/GeneralContext"

const { Sider } = Layout

const SideMenu: FC = () => {
  const {isSalesPerson, isAdmin} = useGeneralContext()
  const { pathname } = useLocation()
  const [menuItems, setMenuItems] = useState<ItemType[] | undefined>(undefined)
  const {data, loading} = useLayoutQuery()

  useEffect(() => {
    const menuFromStrapi = data?.layout?.data?.attributes?.headerMenu?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                           href={it?.url as string} />
      }
    })
    const menuFromStrapiWithoutLocations = data?.layout?.data?.attributes?.headerMenu?.filter(item => item?.title !== "Locations" && item?.title !== "Profit")?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                            href={it?.url as string} />
      }
    })
    const adminMenu = data?.layout?.data?.attributes?.adminMenu?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                            href={it?.url as string} />
      }
    })
    setMenuItems(
      // @ts-ignore
      isAdmin ? adminMenu : isSalesPerson ? menuFromStrapiWithoutLocations : menuFromStrapi
    )
  }, [loading])

  return (
    <>
      <Sider
        width={200}
        theme={"light"}
        style={{
          position: "fixed",
          height: "100%",
          minHeight: "100vh",
          borderRight: "1px solid #e5e7eb",
          padding: "20px 0",
        }}
      >
        <Link to={"/"}>
          <Logo />
        </Link>

        <Navigation
          style={{marginTop: 36, border: "none"}}
          mode={"vertical"}
          activeKey={pathname}
          defaultActiveFirst
          items={menuItems}
        />
      </Sider>
    </>
  )
}

export { SideMenu }
