import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import AuthLayout from "../components/auth-layout"
import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const Products = lazy<FC>(() => import("./products"))
const Purchases = lazy<FC>(() => import("./purchases"))
const Sales = lazy<FC>(() => import("./sales"))
const Profit = lazy<FC>(() => import("./profit"))
const Team = lazy<FC>(() => import("./team"))
const Locations = lazy<FC>(() => import("./locations"))
const Settings = lazy<FC>(() => import("./settings"))
const Notifications = lazy<FC>(() => import("./notifications"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const ForgotPassword = lazy<FC>(() => import("./forgot-password"))
const ResetPassword = lazy<FC>(() => import("./reset-password"))
const AdminDashboard = lazy<FC>(() => import("./owner-dashboard"))
const AdminUsers = lazy<FC>(() => import("./owner-users"))
const AdminSettings = lazy<FC>(() => import("./owner-settings"))
const PaymentCallBack = lazy<FC>(() => import("./payment-callback"))
const SelectPlan = lazy<FC>(() => import("./sign-up/SelectPlan"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Dashboard,
        path: "/dashboard",
        index: true,
      },
      {
        Component: Products,
        path: "/products",
        index: true,
      },
      {
        Component: Purchases,
        path: "/purchases",
        index: true,
      },
      {
        Component: Sales,
        path: "/sales",
        index: true,
      },
      {
        Component: Profit,
        path: "/profit",
        index: true,
      },
      {
        Component: Team,
        path: "/team",
        index: true,
      },
      {
        Component: Locations,
        path: "/locations",
        index: true,
      },
      {
        Component: Notifications,
        path: "/notifications",
        index: true,
      },
      {
        Component: Settings,
        path: "/settings",
        index: true,
      },
      {
        Component: AdminDashboard,
        path: "/owner-dashboard",
        index: true,
      },
      {
        Component: AdminUsers,
        path: "/owner-users",
        index: true,
      },
      {
        Component: AdminSettings,
        path: "/owner-settings",
        index: true,
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
  {
    Component: AuthLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: SignIn,
        path: "/sign-in",
        index: true,
      },
      {
        Component: SignUp,
        path: "/sign-up",
        index: true,
      },
      {
        Component: SelectPlan,
        path: "/sign-up/subscription",
        index: true,
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
        index: true,
      },
      {
        Component: ResetPassword,
        path: "/reset-password",
        index: true,
      },
      {
        Component: PaymentCallBack,
        path: "/paystack/callback",
        index: true,
      },
    ],
  },
]

export default routes
