import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect, useState } from "react"
import { Outlet,useLocation,useNavigate } from "react-router-dom"
import { useGeneralContext } from "../../context/GeneralContext"
import { useMeQuery } from "../../graphql"
import { SideMenu } from "./sidebar"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const Layout: FC = () => {
  const { data, loading, refetch } = useMeQuery()
  const {shop, setShop, profile, setProfile, isAdmin, setIsAdmin, setIsSalesPerson, setAccount, setShopOptions} = useGeneralContext()
  const [subscription, setSubscription] = useState<string | null | undefined>(undefined)
  const query = useQuery()
  const location = useLocation()
  const navigate = useNavigate()
  const shopQuery = query.get("shop")


  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setShopOptions(data?.me?.account?.data?.attributes?.shops?.data?.map(shop => {
      return {
        label: `${shop?.attributes?.name}`,
        value: shop?.id as string
      }
    }).sort((a, b) => a.label.localeCompare(b.label)) as { label: string; value: string }[])
    setShop(shopQuery ? shopQuery : data?.me?.account?.data?.attributes?.shops?.data?.[0]?.id as string)
    setAccount(data?.me?.account?.data?.id as string)
    setProfile(data?.me as UsersPermissionsUser)
    setIsSalesPerson(data?.me?.role?.data?.attributes?.name === "Sales Staff")
    setIsAdmin(data?.me?.role?.data?.attributes?.name === "SuperAdmin")
    setSubscription( data?.me?.account?.data?.attributes?.subscription?.data?.id)
  }, [location.pathname, data, loading])

  useEffect(() => {
    if (location.pathname === "/" && isAdmin || location.pathname === "/dashboard" && isAdmin) {
      navigate("/owner-dashboard")
    }
    else if (location.pathname === "/") {
      navigate("/dashboard")
    }
  }, [location.pathname, isAdmin])

  useEffect(() => {
    if (shop && !isAdmin) {
      query.set("shop", `${shop}`)
      navigate(`${location.pathname}?${query.toString()}`, { replace: true })
    }
  }, [loading, isAdmin])
  useEffect(() => {
    const authToken = localStorage.getItem("jwt")
    if (authToken && !data?.me?.account?.data?.attributes?.subscription?.data?.id) {
      navigate("/sign-up/subscription")
    }
    if (!authToken) {
      navigate("/sign-in")
    }

  }, [location.pathname, data])

  return (
    <BaseLayout style={{ minHeight: "100vh" }}>
      <BaseLayout.Sider>
        {profile && <SideMenu />}
      </BaseLayout.Sider>
      <BaseLayout style={{ backgroundColor: "#F6F8F9" }}>
        <Suspense>
          {
            data?.me && <Outlet />
          }
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  )
}

export { Layout }
